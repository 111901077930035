import React from 'react'
import aboutconpany from "../../Images/about/aboutUs.png";

const AboutOurCompany = () => {
    return (
        <>
            {/*--ABOUT OUR COMPANY----*/}
            <section className="aboutcompany_sec">
                <div className="cust_container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <h6 className="sec_name text-left">About Our Organization</h6>
                            <h4 className="sec_heading text-left ml-0">
                                Recon Infinity Foundation
                            </h4>
                            <p className="sec_desc">
                                Welcome to Recon Infinity Foundation. We are a non-profit organization
                                based in Tinsukia, Assam, which is dedicated to safeguarding our youth
                                and educating parents about the dangers of illicit drug and substance use.
                                Our mission is driven by a commitment towards awareness and prevention
                                through various educational strategies.
                            </p>

                            <h6 className='para_heading'>Our Goal:</h6>

                            <p className="sec_desc">Infinity Foundation aims to be a beacon of hope and
                                knowledge. By educating and raising awareness, we strive to create a
                                safer, healthier community where everyone is informed about the dangers
                                of drug use and equipped to make positive choices.</p>

                            <p className="sec_desc">Join us in our mission to spread awareness and foster
                                a drug-free society.</p>

                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <figure className="aboutcomimg_wrap">
                                <img
                                    src={aboutconpany}
                                    className="img-fluid"
                                    alt=".."
                                />
                            </figure>
                        </div>
                    </div>

                    <h6 className='para_heading'>Our Mission:</h6>

                    <p className="sec_desc">At Recon Infinity Foundation, we strive to create a
                        drug-free Assam by leveraging technology intervention as a powerful tool
                        for knowledge sharing and awareness. Our primary focus is on:</p>

                    <p className="sec_desc">Educational Awareness: We develop apps and websites
                        specifically designed to educate individuals about the dangers of illicit
                        drug and substance use.</p>

                    <p className="sec_desc">Knowledge Sharing Platform:Our platform allows anyone
                        to contribute by posting text, photos, and videos related to substance use
                        dangers. This inclusive approach welcomes contributions from doctors,
                        mental health workers, recovery addicts, substance users, and non-addicts
                        alike.</p>

                    <p className="sec_desc">Community Engagement: Through open discussions and
                        shared experiences, our platform encourages dialogue among users to
                        promote awareness, offer support, and share insights into recovery
                        and prevention.</p>

                    <p className="sec_desc">Parental Education: We offer a specialized
                        5-days awareness course for parents on how to protect their
                        children from illicit drug use, providing essential knowledge
                        and resources.</p>

                    <h6 className='para_heading'>Our Community:</h6>

                    <p className="sec_desc">
                        Our platform is a knowledge hub where :-
                    </p>
                    <ul className="listmenu">
                        <li className="item">
                            Doctors and Mental Health Workers provide valuable insights and guidance.
                        </li>
                        <li className="item">
                            Recovery Substance user share their personal journeys and advice on overcoming substance use.
                        </li>
                        <li className="item">
                            Substance Users seek support and learn about recovery options.
                        </li>
                        <li className="item">
                            General People educate themselves on the dangers of drug use and contribute to a supportive community environment.
                        </li>
                    </ul>


                    <h6 className='para_heading'>Join Us:</h6>

                    <p className="sec_desc">
                        Individuals from all walks of life - whether doctors, mental health workers, recovery
                        addicts, substance users or concerned citizens - are encouraged to register with
                        Recon Infinity Foundation. By sharing experiences, knowledge, and suggestions,
                        together we can break the chain surrounding drug use and foster a positive,
                        supportive environment.
                    </p>

                    <h6 className='para_heading'>Together Towards a Drug-Free Assam:</h6>

                    <p className="sec_desc">
                        Join us in our mission to protect our youth, educate our communities, and build
                        a safer, drug-free Assam. Together, we can make a difference and create a brighter
                        future for generations to come.
                    </p>


                </div>
            </section>
        </>

    )
}

export default AboutOurCompany