import React from 'react'

const HomeService = () => {
    return (
        <>
            <section className="service_sec" id='ServiceId'>
                <div className="cust_container">
                    <div className="bgwhite">
                        <h6 className="sec_name">Our Services</h6>
                        <h4 className="sec_heading">
                            Join us in our mission to spread awareness and foster a drug-free society
                        </h4>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="servicebox">
                                    <figure className="icon">
                                        <i className="fa-solid fa-earth-americas" />
                                    </figure>
                                    <a href="#" className="head">
                                        Workshops and
                                        <br /> Seminars
                                    </a>
                                    <p className='desc'>Interactive sessions that provide detailed
                                        information on drug use, its effects, and strategies for
                                        prevention and recovery.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="servicebox">
                                    <figure className="icon">
                                        <i className="fa-solid fa-school-circle-check" />
                                    </figure>
                                    <a href="#" className="head">
                                        Digital Resources
                                    </a>
                                    <p className='desc'>Accessible online content, including articles,
                                        videos, and webinars, that reach a broader audience and provide
                                        continuous learning opportunities.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="servicebox">
                                    <figure className="icon">
                                        <i className="fa-solid fa-user-doctor" />
                                    </figure>
                                    <a href="#" className="head">
                                        Support Programs
                                    </a>
                                    <p className='desc'>Initiatives that offer guidance and support
                                        to substance users, helping them on their path to recovery.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeService