import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import './App.css';
import MainLayout from "./Layout/MainLayout";
import Home from "./View/Home/Index";
import About from "./View/About/Index";
import Contact from "./View/ContactUs/Index";
import PrivacyPolicy from "./View/Otherpage/PrivacyPolicy";
import TermsConditions from "./View/Otherpage/TermsConditions";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
