import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import bannerpic1 from "../../Images/banner/banner1.png";
import bannerpic2 from "../../Images/banner/banner2.png";

const HomeBanner = () => {

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        if (swiper) {
            swiper.on('autoplayTimeLeft', (s, time, progress) => {
                if (progressCircle.current) {
                    progressCircle.current.style.setProperty('--progress', 1 - progress);
                }
                if (progressContent.current) {
                    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
                }
            });
        }
    }, [swiper]);

    return (
        <>
            <section className="banner_sec">
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    }}
                    onSwiper={setSwiper}
                >
                    <SwiperSlide>
                        <figure className="image_wrap">
                            <img src={bannerpic1} className="img-fluid" alt=".." />
                        </figure>
                    </SwiperSlide>
                    <SwiperSlide>
                        <figure className="image_wrap">
                            <img src={bannerpic2} className="img-fluid" alt=".." />
                        </figure>
                    </SwiperSlide>
                    <div className="swiper-pagination"></div>
                    <div className="autoplay-progress">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>
                </Swiper>
            </section>
        </>
    )
}

export default HomeBanner