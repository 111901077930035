import React, { useEffect, useState } from 'react';
import logo from "../Images/recon-logo.png";
import { Link, NavLink } from 'react-router-dom';
import SideBar from './SideBar';

const Header = () => {

  //side bar open
  const [isOpen, setIsOpen] = useState(false);
  const openSidebar = () => {
    setIsOpen(true);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };


  //header sticky
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("mainheader");
      const sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      {/*--top header--*/}
      {/* <section className="topheader_wrap">
        <div className="cust_container">
          <div className="content_area">
            <div className="left">
              <a href="#" className="nevlist">
                <span>
                  <i className="fa-solid fa-location-dot" />
                </span>
                DIGBOI (TINGRAI) ,PO-TINGRAI, DIST-TINSUKIA, ASSAM 786171
              </a>
              <a href="#" className="nevlist">
                <span>
                  <i className="fa-solid fa-envelope" />
                </span>
                reconinfinity.hq@gmail.com
              </a>
            </div>
            <div className="right">
              <p className="text">A Division of Drug Free Assam Foundation, Inc.</p>
            </div>
          </div>
        </div>
      </section> */}

      {/*--main header--*/}
      <header id="mainheader" className={isSticky ? "mainheader_wrap sticky" : "mainheader_wrap"}>
        <div className="cust_container">
          <div className="contant_wrap">
            <Link to="/" className="logo_wrap">
              <img
                src={logo}
                className="img-fluid"
                alt="logo"
              />
            </Link>
        
              <ul className="navlist">
                <li className="navlink">
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="navlink">
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li className="navlink">
                  <a href="#ServiceId">Service</a>
                </li>
                <li className="navlink">
                  <NavLink to="/contact-us">Contact Us</NavLink>
                </li>
              </ul>
        

            <button className="hambarger_btn" onClick={openSidebar}>
              <i className="fa-solid fa-bars-staggered" />
            </button>

          </div>
        </div>
      </header>


      {/***Side bar****/}
      <SideBar closeSidebar={closeSidebar} isOpen={isOpen} />

    </>

  )
}

export default Header