import React from 'react';
import Marquee from "react-fast-marquee";

const HomeMarquee = () => {
    return (
        <section className="marquee_sec">
            <Marquee className="text" velocity={10} direction="left" pauseOnHover={true} >
                Join us in our mission to spread awareness and foster a drug-free society.
            </Marquee>
        </section>
    )
}

export default HomeMarquee