import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';

import drug1 from "../../Images/drug-1.jpg";
import drug2 from "../../Images/drug-2.jpg";
import drug3 from "../../Images/drug-3.jpg";

import homeaboutimg from "../../Images/about/aboutUs.png";

const HomeAbout = () => {
    return (
        <>
            <section className="square-swiper-container">

                <div className="cust_container">

                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12'>
                            
                            {/* <figure>
                                <div className="squarefadeSwiper">

                                    <div className="flex-center hero-logo">
                                        <svg
                                            className="onehumanfamily-overlay-logo-svg rotate"
                                            id="header_logo_svg"
                                            viewBox="0 0 504 504"
                                        >
                                            <path
                                                className="rb-red"
                                                d="M252,26.9c-5.4,0.1-10.4-3-12.5-8c-2.2-5-1.1-10.7,2.6-14.6c3.8-3.9,9.5-5.1,14.5-3.1c5,2,8.3,6.9,8.4,12.3
	C265.1,20.8,259.3,26.8,252,26.9L252,26.9z"
                                            />
                                            <path
                                                className="rb-red"
                                                d="M266.9,50.8l32.8-48.6L294.4,0l-31,32.1h-22.9L209.5,0l-5.3,2.2l32.8,48.6l-0.2,33l-9.6,80.1h9L252,97.3
	l15.7,66.6h9l-9.6-80.1L266.9,50.8z"
                                            />
                                            <path
                                                className="rb-turquoise"
                                                d="M252.3,477.2c5.4,0,10.3,3.2,12.4,8.2c2.1,5,0.9,10.7-2.9,14.6c-3.8,3.8-9.6,5-14.6,2.9c-5-2.1-8.2-7-8.2-12.4
	C239,483.1,245,477.2,252.3,477.2L252.3,477.2z"
                                            />
                                            <path
                                                className="rb-turquoise"
                                                d="M237.1,453.1l-32.8,48.6l5.3,2.3l31-32.1h22.9l31,32.1l5.3-2.3l-32.8-48.6l0.2-33l9.6-80.1h-9L252,406.6
	L236.3,340h-9l9.6,80.1L237.1,453.1z"
                                            />
                                            <path
                                                className="rb-pink"
                                                d="M92.6,93c-3.8,3.8-9.5,5-14.5,2.9c-5-2.1-8.2-6.9-8.2-12.3c0-5.4,3.2-10.3,8.2-12.3c5-2.1,10.7-0.9,14.5,2.9
	C97.8,79.3,97.8,87.8,92.6,93L92.6,93z"
                                            />
                                            <path
                                                className="rb-pink"
                                                d="M120.3,99.2l-11.2-57.6l-5.3,2.1l0.8,44.7l-8.1,8.1h-0.1l-8,8.1l-44.7-0.7l-2.1,5.3l57.6,11.1l23.1,23.5
	l49.8,63.5l6.4-6.4l-36-58.2l58.2,36l6.4-6.4l-63.5-49.9L120.3,99.2z"
                                            />
                                            <path
                                                className="rb-green"
                                                d="M411.4,411c3.8-3.8,9.5-5,14.5-2.9c5,2.1,8.2,6.9,8.2,12.3s-3.2,10.3-8.2,12.3c-5,2.1-10.7,0.9-14.5-2.9
	C406.2,424.6,406.2,416.2,411.4,411L411.4,411z"
                                            />
                                            <path
                                                className="rb-green"
                                                d="M383.7,404.7l11.2,57.6l5.3-2.1l-0.8-44.7l16.1-16.1l44.7,0.7l2.1-5.3l-57.6-11.1l-23.1-23.5l-49.8-63.5
	l-6.4,6.4l36,58.2l-58.3-36l-6.4,6.4l63.5,49.9L383.7,404.7z"
                                            />
                                            <path
                                                className="rb-purple"
                                                d="M26.8,252.3c0,5.4-3.2,10.2-8.2,12.3c-5,2.1-10.7,0.9-14.5-2.9c-3.8-3.8-4.9-9.5-2.9-14.5
	c2.1-5,6.9-8.2,12.3-8.2c3.5,0,6.9,1.4,9.4,3.9C25.4,245.3,26.8,248.7,26.8,252.3L26.8,252.3z"
                                            />
                                            <path
                                                className="rb-purple"
                                                d="M50.9,237.1L2.3,204.2L0,209.5l32.1,31.1v22.8L0,294.5l2.3,5.3l48.6-32.8l32.9,0.2l80.2,9.6v-9L97.4,252
	l66.6-15.7v-9.1l-80.2,9.6L50.9,237.1z"
                                            />
                                            <path
                                                className="rb-yellow"
                                                d="M477.2,251.7c0-5.4,3.3-10.3,8.2-12.3c5-2.1,10.7-0.9,14.5,2.9c3.8,3.8,4.9,9.6,2.9,14.5c-2.1,5-7,8.2-12.4,8.2
	c-3.5,0-6.9-1.4-9.4-3.9C478.6,258.6,477.2,255.2,477.2,251.7L477.2,251.7z"
                                            />
                                            <path
                                                className="rb-yellow"
                                                d="M453.1,266.9l48.6,32.8l2.3-5.3l-32.1-31.1v-22.8l32.1-31.1l-2.3-5.3l-48.6,32.9l-32.9-0.3l-80.2-9.6v9.1
	l66.6,15.7L340,267.7v9l80.2-9.6L453.1,266.9z"
                                            />
                                            <path
                                                className="rb-blue"
                                                d="M93,411.4c3.8,3.8,5,9.5,2.9,14.5c-2.1,5-6.9,8.2-12.3,8.2S73.3,431,71.2,426c-2.1-5-0.9-10.7,2.9-14.5
	c2.5-2.5,5.9-3.9,9.4-3.9S90.5,408.9,93,411.4L93,411.4z"
                                            />
                                            <path
                                                className="rb-blue"
                                                d="M99.2,383.7l-57.6,11.1l2.1,5.3l44.7-0.7l8,8.1h0.1l8.1,8.1l-0.8,44.7l5.3,2.1l11.2-57.6l23.5-23.1l63.5-49.9
	l-6.4-6.4l-58.3,36l36-58.2l-6.4-6.4l-49.8,63.5L99.2,383.7z"
                                            />
                                            <path
                                                className="rb-orange"
                                                d="M411,92.5c-5.2-5.2-5.2-13.6,0-18.8c5.2-5.2,13.6-5.2,18.8,0c5.2,5.2,5.2,13.6,0,18.8
	C424.6,97.7,416.2,97.7,411,92.5L411,92.5z"
                                            />
                                            <path
                                                className="rb-orange"
                                                d="M404.7,120.3l57.6-11.1l-2.1-5.3l-44.7,0.7l-8.1-8.1l-8.1-8.1l0.8-44.7l-5.3-2.1l-11.2,57.6l-23.5,23.1
	l-63.5,49.9l6.4,6.4l58.3-36l-36,58.2l6.4,6.4l49.8-63.5L404.7,120.3z"
                                            />
                                        </svg>
                                    </div>

                                    <Swiper
                                        modules={[Autoplay, Pagination, Navigation, EffectFade]}
                                        spaceBetween={30}
                                        centeredSlides={true}
                                        effect="fade"
                                        speed={2500}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            el: '.swiper-pagination',
                                            clickable: true,
                                        }}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                        className="squarefadeSwiper"
                                    >
                                        <SwiperSlide>
                                            <div className="slide-content" style={{ width: "100%", height: "100%" }}>
                                                <img
                                                    src={drug1}
                                                    className="img-fluid"
                                                    alt="..."
                                                />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="slide-content" style={{ width: "100%", height: "100%" }}>
                                                <img
                                                    src={drug2}
                                                    className="img-fluid"
                                                    alt="..."
                                                />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="slide-content" style={{ width: "100%", height: "100%" }}>
                                                <img
                                                    src={drug3}
                                                    className="img-fluid"
                                                    alt="..."
                                                />
                                            </div>
                                        </SwiperSlide>

                                        <div className="swiper-pagination"></div>
                                    </Swiper>
                                </div>
                            </figure> */}

                            <figure className='leftimg_wrap'>
                                <img src={homeaboutimg} className='img-fluid' alt='..' />
                            </figure>
                        </div>
                        <div className='col-lg-8 col-md-6 col-12'>
                            <article>
                                <div>
                                    <h2>About</h2>
                                    <h3>Together Towards a Drug-Free Assam</h3>
                                    <p className="has-dropcap">
                                        Welcome to Recon Infinity Foundation. We are a non-profit
                                        organization based in Tinsukia, Assam, which is dedicated
                                        to safeguarding our youth and educating parents about the
                                        dangers of illicit drug and substance use. Our mission is
                                        driven by a commitment towards awareness and prevention through
                                        various educational strategies.
                                        <br />
                                        <br />
                                        Individuals from all walks of life - whether doctors, mental
                                        health workers, recovery addicts, substance users or concerned
                                        citizens - are encouraged to register with Recon Infinity Foundation.
                                        By sharing experiences, knowledge, and suggestions, together
                                        we can break the chain surrounding drug use and foster a positive,
                                        supportive environment.
                                        <br />
                                        <br />
                                        Join us in our mission to protect our youth, educate our communities,
                                        and build a safer, drug-free Assam. Together, we can make a difference
                                        and create a brighter future for generations to come.
                                    </p>

                                </div>
                            </article>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default HomeAbout