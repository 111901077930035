import React from 'react'
import "../Otherpage/Otherpage.css"

const PrivacyPolicy = () => {
    return (
        <>
            <section className='otherpage_sec'>
                <div className='cust_container'>
                    <h2 className='page_head'><span>Privacy Policy</span></h2>
                    <p className='desc'> This Privacy Policy (“Policy”) of us administers the manner in which Recon Infinity (the
                        “Company” or “We”) collect, gains access to, uses maintains, and unveils information
                        including personal information of the users (each, a “User”) of various services (“Services”)
                        provided by the Company, either through the website of the Company or through mobile
                        applications or network telecom operators. Please note that the scope of this Policy is
                        limited to the aforementioned information collected or received by the Company through
                        your use of the Services. By using the Service, you are expressing your agreement to this
                        Policy and the handling of your information, including your personal information, in the
                        manner provided in this Policy. If you do not agree to these terms, please do not use the
                        Service and do not provide the permissions when precisely asked by the Company</p>

                    <h4 className='sub_heading'>What we collect?</h4>

                    <p className='desc'>We may collect and/or gain access to and/or record certain information including personal
                        information from or of User in a variety of ways, including, but not limited to, when User
                        access and uses the Services and in connection with other activities, services, features or
                        resources we make available on the Services. These information may relate to and include
                        but not limited to your name, email address, address, phone number, your unique device
                        ID, hardware type, international mobile equipment identity (“IMEI”), the version of your
                        operating system (“OS”), your device name, your email address (if you have connected to
                        Facebook or Google+), and your location (based on your Internet Protocol (“IP”) address),
                        contact list (on your mobile phone), applications installed by User through our mobile
                        application, applications uninstalled by User, all other applications installed by User on
                        his/her device, foreground running apps/process (RUN event; We update server about the
                        app downloaded/installed by our mobile application), read SMS, network information, User
                        behavior analysis, demographic information (such as preferences and interests etc),
                        Credit/Debit Card information of User for Internet banking (We do not store credit/debit
                        card information on our servers) [hereinafter referred to as “Personal Information”]. Users
                        can always refuse to supply Personal Information; however, it may prevent them from
                        fetching in certain Services related activities.
                    </p>

                    <h4 className='sub_heading'>What we do with Your Personal Information?</h4>

                    <p className='desc'>We may use your Personal Information for the activities of:</p>


                    <ul className='list'>
                        <li>Process transactions</li>
                        <li>Personalize the Services for User.</li>
                        <li>Send User our newsletter.</li>
                        <li>Administer the Services.</li>
                        <li>Process installation.</li>
                        <li>End to User direct links to the Services.</li>
                        <li>Develop, deliver, and improve our products, services, content, and publicity.</li>
                        <li>Auditing, data analysis, and research to improve the Services.</li>
                        <li>Send key notices, such as communications about purchases/downloads and changes to our Policy.</li>
                        <li>Send alerts to User.</li>
                        <li>Troubleshooting and helping us to understand usage movements.</li>
                        <li>Marketing and campaign of the Services.</li>
                    </ul>


                    <h4 className='sub_heading'>Collection and use of Your non-personal information</h4>

                    <p className='desc'>We may collect non-personal information about Users whenever they use and relate with
                        the Services. Non-personal information may include the browser name, the type of
                        computer, and technical information about means used by the User to connect to our
                        Services, such as the information about the operating system and the internet service
                        providers operated and other related information.</p>

                    <p className='desc'>This information is combined and used to help us provide more useful information to the
                        User and to know which part of the Services, its products, and services are of most
                        interest.
                    </p>

                    <p className='desc'>If we do combine non-personal information with Personal Information, the combined
                        information will be treated as Personal Information for as long as it remains combined.
                    </p>

                    <h4 className='sub_heading'>Distribution of information with Authorities</h4>

                    <p className='desc'>We may share Personal Information as well as non-personal information of the User with
                        the courts, police authorities, or any other government/regulatory/statutory authority, in
                        case these are required by them in relation to any actions pending before them.
                    </p>

                    <h4 className='sub_heading'>Use of cookies</h4>

                    <p className='desc'>A cookie is a small file that asks permission to be positioned on the User’s
                        desktop/laptop/mobile device's memory drive. Once the User agrees, the file is added and
                        the cookie helps analyze web traffic. Cookies allow Services to respond to the User as an
                        individual. The Services can alter its operations to the User’s needs, likes, and dislikes by
                        gathering and memorizing information about the User’s likings.</p>
                    <p className='desc'>Cookies help us better understand User’s activities, and tell us which parts of the Services
                        Users have visited, and facilitates and measure the effectiveness of products and services
                        offered by us. We treat information collected by cookies and other technologies as nonpersonal information. However, to the extent that internet protocol (IP) addresses or
                        similar identifiers are considered Personal Information by local law, we also treat these
                        identifiers as Personal Information.
                    </p>
                    <p className='desc'>We use traffic log cookies to identify which pages are being used. This helps us analyze
                        data about web page traffic and improve our Services in order to tailor it to customer
                        needs. We only use this information for statistical analysis purposes and then the data is
                        removed from the system.</p>
                    <p className='desc'>Overall, cookies help us provide Users with a better experience of the Services, by
                        enabling us to monitor which pages Users find useful and which the Users do not. A cookie
                        in no way gives us access to the User’s desktop/laptop/mobile device or any information
                        about the User, other than the data a User chooses to share with us.</p>
                    <p className='desc'>A User can choose to accept or decline cookies. Most web browsers automatically accept
                        cookies, but a User can usually modify its browser setting to decline cookies if the user
                        prefers. This may prevent the User from taking full advantage of the Services.</p>

                    <h4 className='sub_heading'>Information and Data Security</h4>

                    <p className='desc'>We are committed to ensuring that the User’s Personal Information is secure. We
                        implement appropriate data collection, storage, and processing practices and security
                        measures to protect against illegal access, alteration, expose, or damage of the User's
                        Personal Information, username, password, transaction information, and data stored in
                        our Services.</p>

                    <p className='desc'>When a User uses services and products offered by us through the Services, the Personal
                        Information shared by the User is visible to the other users and can be read, collected, or
                        used by them. The User is responsible for the Personal Information User chooses to
                        submit in these examples. For instance, if the User lists his/her name and email address in a blog/forum posting, that information is public. Users are requested to take care when
                        using these features.
                    </p>

                    <h4 className='sub_heading'>Personal Information Retention</h4>
                    <p className='desc'>We will retain User’s Personal Information for the period necessary to fulfill the purposes
                        outlined in this Policy unless a longer retention period is require or allowed by the law.
                    </p>

                    <h4 className='sub_heading'>Managing Third party websites</h4>
                    <p className='desc'>Users may find advertising or other content on the Services that link to the sites and
                        services of our partners, suppliers, advertisers, sponsors, licensors, and other third
                        parties. We do not regulate the content or links that appear on these sites and services
                        and are not liable for the practices employed by those websites and services linked to or
                        from our Services. In addition, these sites and services, including their content and links,
                        may be frequently changing. These sites and services may have their own privacy policies
                        and customer service policies. Browsing and interface on any other website, including
                        websites and services, which have a link to the Services, is subject to that website and
                        services’ terms and policies.</p>

                    <h4 className='sub_heading'>Sharing Personal Information</h4>
                    <p className='desc'>We may use third-party service providers to help us operate our business and Services or
                        manage activities on our behalf, such as sending out newsletters or surveys. We may
                        share the User’s Personal Information with these third parties for those restricted
                        purposes.</p>

                    <h4 className='sub_heading'>Advertising Policy</h4>
                    <p className='desc'>Advertising partners, who may set cookies, may deliver advertisement appearing on the
                        Services to the User. These cookies allow the ad server to recognize the User computer
                        each time they send the User an online advertisement to compile non-personal
                        information about the User or others who use the User computer. This information allows
                        advertisement networks to, among other things, deliver targeted advertisements that they
                        believe will be of most interest to the User. This Policy does not cover the use of cookies
                        by any advertisers.</p>

                    <h4 className='sub_heading'>User acceptance of these terms</h4>
                    <p className='desc'>By using the Services, User signifies his/her acceptance of this Policy. If the User does
                        not agree to this Policy, please do not use the Services. User continued use of the
                        Services following the posting of changes to this Policy would be considered User
                        acceptance of those changes.</p>

                    <h4 className='sub_heading'>Changes to this privacy policy</h4>
                    <p className='desc'>The Company has the discretion to update this Policy at any time. We encourage User to
                        check frequently this page for any changes to stay informed about how we are helping to
                        protect the Personal Information we collect. User accepts and agrees that it is his/her duty
                        to review this Policy occasionally and become aware of changes.</p>

                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy