import React from 'react'
import "../ContactUs/Contact.css"
import ContactBanner from './ContactBanner'
import LetsTalk from './LetsTalk'

const Index = () => {
  return (
    <>
      <ContactBanner />
      <LetsTalk />
    </>
  )
}

export default Index