import React from 'react'
import imageC from "../../Images/app/WHY.jpeg";

const HomeCourse = () => {
    return (
        <>
            <section className="Course_sec">
                <div className="cust_container">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 m-auto">
                            <h6 className="sec_name text-left">Why</h6>
                            <h4 className="sec_heading text-left ml-0">
                                Available Now On The App
                            </h4>
                            <p className="sec_desc">
                                "We have developed awareness and knowledge-sharing apps for everyone
                                because we believe curiosity and reality are fundamental parts of our
                                life journey. Curiosity drives us to explore and experiment, while reality
                                grounds us and encourages understanding. These apps are designed to provide
                                crucial information and guidance before anyone considers experimenting with
                                illegal substances. Additionally, we aim to educate parents on the why,
                                when, what, and where of substance use, helping them understand and
                                address these issues effectively."
                            </p>
                            {/* <p className="sec_desc">
                                <i>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy text
                                    ever since the 1500s, when an unknown printer took a galley of type
                                    and scrambled it to make a type specimen book. It has survived not
                                    only five centuries, but also the leap into electronic typesetting,
                                    remaining essentially unchanged.
                                </i>
                            </p> */}
                            <div className="readmore_btn">
                                <a href="https://play.google.com/store/apps/details?id=com.reconinfinityfoundation" target="_blank" className="btn">
                                    Read More
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="leftwrap">
                                {/* <div className="iphone">
                                    <div className="notch" />
                                    <figure className="screen_img">
                                        <img
                                            src={imageC}
                                            className="img-fluid"
                                            alt="..."
                                        />
                                    </figure>
                                </div> */}
                                <figure className="reconappmobilevew">
                                    <img
                                        src={imageC}
                                        className="img-fluid"
                                        alt="..."
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeCourse