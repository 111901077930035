import React from 'react'
import "../About/About.css";
import AboutpageBanner from './AboutpageBanner';
import AboutOurCompany from './AboutOurCompany';
import AboutpageCounter from './AboutpageCounter';

const Index = () => {
    return (
        <>
            <AboutpageBanner />
            <AboutOurCompany />
            {/* <AboutpageCounter /> */}
        </>
    )
}

export default Index