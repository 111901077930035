import React from 'react'
import imageD from "../../Images/app/DISEASE AND KNOWLEDGE.jpeg";

const HomeDoctor = () => {
    return (
        <>
            <section className="Doctor_sec">
                <div className="cust_container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="leftwrap">
                                {/* <div className="iphone">
                                    <div className="notch" />
                                    <figure className="screen_img">
                                        <img
                                            src={imageD}
                                            className="img-fluid"
                                            alt="..."
                                        />
                                    </figure>
                                </div> */}
                                <figure className="appmobilevew">
                                    <img
                                        src={imageD}
                                        className="img-fluid"
                                        alt="..."
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12 m-auto">
                            <h6 className="sec_name text-left">The Plateform Only For</h6>
                            <h4 className="sec_heading text-left ml-0">
                                AWARENESS AND KNOWLEDGE
                            </h4>
                            <p className="sec_desc">
                                Controlling illicit drug use is a complex and multifaceted challenge.
                                At the heart of this struggle lies a fundamental truth: without knowledge,
                                we are powerless to address it effectively. Just as with any other disease,
                                understanding addiction is crucial to combating it.
                            </p>
                            <p className="sec_desc">
                                <i>
                                    Consider how we treat physical ailments. If someone is suffering from malaria,
                                    viral fever, liver problems, or epilepsy, would we think that hitting them or
                                    causing them physical harm could cure their condition? Of course not.
                                    Similarly, if we insult or ignore them, do we believe that their illness
                                    would simply go away? The answer is unequivocally no.
                                </i>
                            </p>
                            <p className="sec_desc">
                                Addiction, much like these other conditions, is a disease. It's not a moral
                                failing or a simple lack of willpower. Addiction alters the brain's chemistry
                                and function, making it incredibly difficult for individuals to break free
                                without proper help and support.
                            </p>
                            <div className="readmore_btn">
                                <a href="https://play.google.com/store/apps/details?id=com.reconinfinityfoundation" target="_blank" className="btn">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeDoctor