import React from 'react'
import logo from "../Images/recon-logo.png";
import playsore from "../Images/google-play.png";
import appstore from "../Images/app-store.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="mainfooter_sec">
        <div className="cust_container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="box_wrap">
                <figure className="logo_wrap">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="logo"
                  />
                </figure>
                <p className="desc">
                  Join us in our mission to protect our youth, educate our communities,
                  and build a safer, drug-free Assam. Together, we can make a difference
                  and create a brighter future for generations to come.
                </p>
                <h4 className="heading">FOLLOW :</h4>
                <ul className="follow_list">
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa-brands fa-x-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="box_wrap">
                <h4 className="heading">QUICK LINKS :</h4>
                <ul className="menulist">
                  <li className="navlink">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="navlink">
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li className="navlink">
                    <a href="#ServiceId">Service</a>
                  </li>
                  <li className="navlink">
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li className="navlink">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="navlink">
                    <Link to="/terms-conditions">Terms And Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="box_wrap">
                <h4 className="heading">CONTACT US :</h4>
                <p className="desc"><strong>Registered office:</strong> DIGBOI (TINGRAI) ,PO-TINGRAI, DIST-TINSUKIA, ASSAM 786171</p>
                <ul className="contactlist">
                  <li>
                    <a href="#">reconinfinity.hq@gmail.com</a>
                  </li>
                  {/* <li>
                    <a href="#">9876543210</a>
                  </li> */}
                </ul>
                <h4 className="heading">Download :</h4>
                <div className="download_wrap">
                  <a href="https://play.google.com/store/apps/details?id=com.reconinfinityfoundation" target="_blank" className="store_logo">
                    <img
                      src={playsore}
                      className="img-fluid"
                      alt="logo"
                    />
                  </a>
                  {/* <a href="#" className="store_logo">
                    <img
                      src={appstore}
                      className="img-fluid"
                      alt="logo"
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="bottom_footer">
        <div className="cust_container">
          <p className="text">Assam Drug-Free Workplace Alliance © 2024 </p>
        </div>
      </section>
    </>

  )
}

export default Footer