import React from 'react'
import imageE from "../../Images/app/THE BRIDGE.jpeg";

const HomeTheBridge = () => {
    return (
        <>
            <section className="Doctor_sec">
                <div className="cust_container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 col-12">
                            <div className="leftwrap">
                                {/* <div className="iphone">
                                    <div className="notch" />
                                    <figure className="screen_img">
                                        <img
                                            src={imageD}
                                            className="img-fluid"
                                            alt="..."
                                        />
                                    </figure>
                                </div> */}
                                <figure className="ilicitdrugmobilevew">
                                    <img
                                        src={imageE}
                                        className="img-fluid"
                                        alt="..."
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6 col-12 m-auto">
                            <h6 className="sec_name text-left">ILICIT DRUG</h6>
                            <h4 className="sec_heading text-left ml-0">
                                THE BRIDGE
                            </h4>
                            <p className="sec_desc">
                                Infinity Foundation aims to bridge the knowledge gap by spreading
                                awareness about addiction. Without understanding, parents may struggle
                                to find the right solutions for their children. The foundation provides
                                resources, educational programs, and community support to help parents
                                navigate the complexities of addiction.
                            </p>
                            <p className="sec_desc">
                                <i>
                                    In summary, parents need to understand addiction to support their children
                                    effectively, reduce stigma, make informed decisions, and foster recovery.
                                    By spreading knowledge and awareness, organizations like Infinity Foundation
                                    play a vital role in empowering parents and creating healthier communities.
                                </i>
                            </p>

                            <div className="readmore_btn">
                                <a href="https://play.google.com/store/apps/details?id=com.reconinfinityfoundation" target="_blank" className="btn">
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTheBridge