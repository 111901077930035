import React from 'react'
import Background from "../../Images/innerbanner.jpg";

const AboutpageBanner = () => {
    return (
        <section
            className="aboutUs_banner"
            style={{ backgroundImage: `url(${Background})` }}
        >
            <div className="content">
                <div className="cust_container">
                    <h2 className="innerbanner_head">About Us</h2>
                    <nav aria-label="breadcrumb" className="breadcrumbs large-font">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#" role="button" tabIndex={0}>
                                    Home
                                </a>
                            </li>
                            {/* <li className="breadcrumb-item"><a href="#">Registration</a></li> */}
                            <li className="breadcrumb-item active" aria-current="page">
                                About Us
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>

    )
}

export default AboutpageBanner