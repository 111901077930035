import React from 'react'
import logo from "../Images/recon-logo.png";
import { NavLink } from 'react-router-dom';

const SideBar = ({ isOpen, closeSidebar }) => {
    return (
        <section id="sidebar" className={`sidebar ${isOpen ? 'show' : ''}`}>
            <div className="d-flex align-items-center">
                <div className="sidebar_logo">
                    <img
                        src={logo}
                        className="img-fluid"
                        alt="logo"
                    />
                </div>
            </div>
            <a href="#" onClick={closeSidebar} className="closss">
                <i className="fa-solid fa-xmark" />
            </a>
            <ul className="navlist">
                <li className="navlink">
                    <NavLink to="/" onClick={closeSidebar}>Home</NavLink>
                </li>
                <li className="navlink">
                    <NavLink to="/about-us" onClick={closeSidebar}>About Us</NavLink>
                </li>
                <li className="navlink">
                    <a href="#ServiceId" onClick={closeSidebar}>Service</a>
                </li>
                <li className="navlink">
                    <NavLink to="/contact-us" onClick={closeSidebar}>Contact Us</NavLink>
                </li>
                <li className="navlink">
                    <NavLink to="/privacy-policy" onClick={closeSidebar}>Privacy Policy</NavLink>
                </li>
                <li className="navlink">
                    <NavLink to="/terms-conditions" onClick={closeSidebar}>Terms And Conditions</NavLink>
                </li>
            </ul>
        </section>

    )
}

export default SideBar