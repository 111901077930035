import React from 'react'

const TermsConditions = () => {
    return (
        <>
            <section className='otherpage_sec'>
                <div className='cust_container'>
                    <h2 className='page_head'><span>Terms and Conditions </span></h2>
                    <h4 className='sub_heading'>GENERAL</h4>
                    <p className='desc'>These terms and conditions shall administer the accessing and availing by You the
                        Services as detailed below when You as an end user approach us or our retailers for the
                        same. By retrieving and using the Services, you as the End User agree to be guaranteed
                        by these terms and conditions of use, including any additional guidelines and/or future
                        modifications to these terms and conditions ("Terms"). You agree and acknowledge that
                        the access and use of the Services by You is voluntary and of Your own accord, and that
                        the Terms shall be binding on you, for access and use of the Services, in India ("Territory").
                        Your access and use of the Services shall be subject to the applicable laws, rules and
                        regulations in force in the Territory, and as may be amended from time to time ("Applicable
                        Laws") Your act of accessing and using the Services, shall be deemed to be Your absolute
                        agreement to these Terms and Privacy Policy (defined below) of ("Recon infinity") as
                        agreed from time to time.</p>

                    <h4 className='sub_heading'>PRIVACY POLICY & DATA PROTECTION</h4>
                    <p className='desc'>Any personal information You supply to Recon infinity when Recon infinity will use using
                        the Services in accordance with its privacy policy ("Privacy Policy") as available in the
                        Website.</p>

                    <h4 className='sub_heading'>SERVICES</h4>
                    <p className='desc'>Services herein shall mean the payment services availed by You as an end user
                        ("You/End User") through Recon infinity and shall be linked to Your registered mobile
                        number. </p>

                    <h4 className='sub_heading'>PROPRIETARY RIGHTS AND LICENCE</h4>
                    <p className='desc'>All trademarks, copyright, database rights and other intellectual property rights of any
                        nature in the Website and Services, any name, mark or logo related to Recon infinity and/or
                        the Services are owned either directly by Recon infinity or Recon infinity’s licensors. You
                        should not adapt or use or else any name, mark or logo that is alike, or puzzlingly similar to
                        any of these marks and logos. You agree and acknowledge that the access and use of the
                        Services and Website shall not be took to consult on You any rights of any nature including
                        rights in any form of intellectual property controlled in the Services and/or Website or related
                        to Recon infinity. If in the course of accessing and using the Services and Website, You
                        even unintentionally infringe the rights including rights in any form of intellectual property of
                        Recon infinity or Recon infinity’s licensors in the Services, You shall immediately cease and
                        desist any such use.</p>
                    <p className='desc'>You agree and confirm that You shall not at any time reveal or make public any and all
                        proprietary and/or confidential information relating to Recon infinity, its business, the
                        Services, that You may gain access to during the course of accessing and using the
                        Services. Except as specifically authorized by Recon infinity, You agree not to modify, rent,
                        lease, loan, sell, distribute or create derivative works based on the content included in the
                        Website or Services, in whole or in part.</p>

                    <h4 className='sub_heading'>CONDITIONS OF USE</h4>
                    <p className='desc'>The Website and the Services are not available to persons under the age of 18 or to
                        anyone previously deferred or removed from the Services by Recon infinity. By accepting
                        these Terms and/or by using the Website/Services, You signify that You are at least of the
                        age of 18 years.
                    </p>
                    <p className='desc'>The Website and the Services here under are presently made available to You for Your
                        personal and non-commercial use. Recon infinity reserves the right to modify or remove the
                        Website and the Services, provided to You in accordance with these Terms, at any time and
                        for any reason.</p>
                    <p className='desc'>You acknowledge that the terms of agreement with Your respective telecom or internet
                        service provider will continue to apply when using the Services. As a result, You may be
                        charged by the respective telecom or internet service provider for access to network
                        connection services for the period of the connection while accessing the Website and the
                        Services or any such third party charges as may arise. You accept responsibility for any
                        such charges that arise, and shall not claim the same from Recon infinity or dispute the
                        same.</p>
                    <p className='desc'>If you are not the bill payer for the internet services provided by the telecom
                        operator/internet service provider being used to access the Services, You will be assumed
                        to have received authorization from the bill payer for using the Services.</p>
                    <p className='desc'>You are exclusively responsible for your use of the Services. Recon infinity does not
                        create, monitor, examine or check the content delivered to You through the Services, if any.
                        Such content may include links to third-party websites and content. The linked content is not
                        under Recon infinity’s control and Recon infinity is not responsible whatsoever for the
                        content of any such linked content. The content does not represent or reflect any advice,
                        views, opinions or beliefs of Recon infinity. Further, you are advised to lodge a complaint
                        (on www.Recon infinity.org).</p>
                    <p className='desc'>You must only use the Services and anything available via the Services for lawful
                        purposes (complying with all Applicable Laws), in an accountable manner, and not in a way
                        that might damage Recon infinity’s name or reputation or that of any of Recon infinity’s
                        associates or service providers. You agree and acknowledge that the use of the information
                        delivered to You through the Services is Your sole responsibility and that Recon infinity shall
                        not at any time be held responsible for any illegitimate usage of the information secured
                        through the Services, by You.
                    </p>
                    <p className='desc'>You may find advertising or other content delivered with the use of Services that
                        connected to the sites and mobile applications and services of Recon infinity’s partners,
                        advertisers, promoters, licensors and other third parties and service providers. Recon infinity
                        does not control the content, advertisement or links that appear through the Services and
                        are not responsible for the practices employed by those websites and mobile applications
                        linked to or from the Services. In addition, these sites and mobile applications or services,
                        including their content and links, may be constantly changing. These sites and mobile
                        applications and services may have their own terms of use and customer service policies.
                        Browsing and interaction on any other website, advertisements and mobile applications
                        which have a link in the Services, is subject to that website’s, advertisements and mobile
                        applications own terms and policies. You may find campaigns and offers organized by
                        Recon infinity (on its own or along with Recon infinity’s partners, Suppliers, advertisers,
                        sponsors, licensors and other third parties and service providers), from time to time which
                        may be made available to You in the course of Your access and use of Services. If You
                        choose to participate in such campaigns and offers, You agree that Your involvement shall
                        be voluntary and at Your sole discretion. Further, You should read and understand fully the
                        contents of such campaigns and offers and should be in agreement with them before Your
                        involvement. These campaigns and offers may have their own terms of use and customer
                        service policies available on their respective websites and You agree to such terms of use
                        and policies before You participate in any of them. Recon infinity shall not be liable for any
                        costs, obligations, expenses and damages to You or any third parties arising out of and in
                        course of Your participation in such operations and offers and Your availing the benefits
                        therefrom.</p>
                    <p className='desc'>You will not, nor allow third parties on Your behalf to (i) make and dispense copies of
                        the Website (ii) attempt to copy, replicate, alter, modify, reverse engineer, disassemble,
                        decompile, transfer, altercation or translate the Website; or (iii) create derivative works of
                        the Website of any kind whatsoever.</p>
                    <p className='desc'>You shall not host, display, upload, modify, circulate, transmit, update or share any
                        information that:</p>
                    <ul className='list'>
                        <li>belongs to another person and to which You do not have any right to;</li>
                        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
                            pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically
                            objectionable, disparaging, relating or encouraging money laundering or gambling, or
                            otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully
                            harassing including but not limited to "indecent representation of women" within the meaning
                            of the Indecent Representation of Women (Prohibition) Act, 1986;
                        </li>
                        <li> harm minors in any way;</li>
                        <li>trespasses any patent, trademark, copyright or other proprietary rights or third party’s
                            trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of
                            counterfeit or stolen items;
                        </li>
                        <li>violates any law for the time being in force;</li>
                        <li> cheats or misleads the addressee/ users about the origin of such messages or
                            communicates any information which is grossly offensive or menacing in nature;</li>
                        <li>impersonate another person;</li>
                        <li>contains software viruses or any other computer code, files or programs designed to
                            interrupt, destroy or limit the functionality of any computer resource; or contains any Trojan
                            horses, worms, time bombs, cancellous, Easter eggs or other computer programming
                            routines that may damage, harmfully interfere with, diminish value of, surreptitiously
                            intercept or expropriate any system, data or personal information;
                        </li>
                        <li> impends the unity, integrity, defense, security or sovereignty of India, friendly relations
                            with foreign states, or public order or causes incitement to the commission of any cognizable
                            crime or prevents investigation of any crime or is insulting any other nation.
                        </li>
                        <li>shall not be false, imprecise or ambiguous;</li>
                    </ul>


                    <h4 className='sub_heading'>CONDITIONS OF USE SPECIFIC TO THE SERVICES</h4>
                    <p className='desc'>You come to an agreement and acknowledge the following:</p>

                    <ul className='list'>
                        <li>You are uniquely responsible for entering the correct information (name, phone number,
                            email address, home address, Date of Birth, Gender etc.);
                        </li>
                        <li> Recon infinity is not responsible for defective internet connection or electricity failure or
                            any other problem on Your side while availing the Services;
                        </li>
                        <li>Recon infinity shall not in any way be answerable for any delay, trouble or defects in the
                            online payment facility provided by any financial institution/bank/payment gateway service
                            provider. You acknowledge that You assume all the risks associated in using the online
                            payment facility provided by the financial institution/bank/payment gateway service
                            providers and Recon infinity shall not be in any way liable for any damage, loss, expense,
                            liability or damage caused (directly or indirectly).
                        </li>
                        <li>You agree that RECON INFINITY shall not be responsible if you share your password
                            and other confidential information about Your User Account with any person or fail to abide
                            by the advice given in these Terms about changing password periodically.</li>
                        <li>You understand and agree that Your User Account is linked to your registered mobile
                            number.
                        </li>
                        <li>RECON INFINITY will not be held liable for unauthorized use of User Account on account
                            of loss/theft/misplacement of Mobile number and/or SIM, unless You have informed RECON
                            INFINITY customer care about such loss/theft/misplacement and have blocked User
                            Account temporarily till You reactivate the same using a new SIM with same mobile number.
                            It must be noted that You shall continue to be held liable for any unauthorized use of your
                            User Account till You inform RECON INFINITY customer care about such
                            loss/theft/misplacement and Your request for blocking your User Account is executed.</li>
                        <li>You agree and acknowledge that RECON INFINITY shall not be responsible for any
                            use/misuse of User Account in any manner whatsoever.</li>
                        <li>RECON INFINITY may amend the procedure and process of creation and operation of
                            User Account in compliance with any laws or direction/order of any government/
                            statutory/regulatory authority and/or court, with or without intimation to the User.
                        </li>
                        <li>You agree and undertake that You shall not use or create fake ID or provide any
                            fake/false profile information for availing the Services through the Website.</li>
                        <li>You agree to promptly notify Recon infinity of any change in Your mobile number or
                            email address or any other details in</li>
                        <li>Recon infinity will not be liable to pay any compensation if any error occurs due to any
                            technical or manual fault at the end of the web server holder.</li>
                        <li>You are solely responsible for Your device and the internet services that connect Your
                            said device to the Website.</li>
                    </ul>

                    <h4 className='sub_heading'>Collection of End User Information </h4>
                    <p className='desc'>You agree and acknowledge that Recon infinity may collect IP address and Website
                        usage information from You. Recon infinity may use the above information, and so on.</p>


                    <h4 className='sub_heading'>Modifications and Interruption to Service</h4>
                    <p className='desc'>Recon infinity reserves the right to modify or discontinue the Service with or without notice
                        to You. Recon infinity shall not be liable to You or any third party should Recon infinity
                        exercise its right to modify or discontinue the Service. You acknowledge and accepts that
                        Recon infinity does not guarantee continuous, uninterrupted or secure access to Website
                        and operation of our Website may be affected with or adversely affected by frequent
                        factors or conditions outside the control of Recon infinity.
                    </p>
                    <ul className='list'>
                        <li>to maintain, operate, provide and enhance our Website and Services; and</li>
                        <li>to comply with legal requirements or process, or where necessary to prevent or investigate
                            fraud, to protect the safety of an individual. Recon infinity may share Your personally
                            recognizable information with suppliers, subcontractors, vendors and other businesses that
                            we employ to perform functions on our behalf. For example, Recon infinity may employ third
                            parties to send email campaigns or provide marketing assistance, or provide customer
                            service. These entities have access to Your information only for the purposes of performing
                            the functions for which we have engaged them. If such entities have access to Your
                            personally recognizable information, their agreements with us require them to keep Your
                            information private.
                        </li>
                    </ul>

                    <h4 className='sub_heading'>AVAILABILITY</h4>
                    <p className='desc'>Recon infinity will use reasonable efforts to make the Services available at all times.
                        However You acknowledge the Services are provided over networks of internet service
                        providers and dependent on them, so the quality and availability of the Services may be
                        affected by factors outside of Recon infinity’s judicious control. In the event the Services are
                        unavailable to You for any factors not attributable to Recon infinity and due to any reasons
                        attributable to any third party service provider, Recon infinity shall not be responsible for the
                        same in any event whatsoever.</p>

                    <p className='desc'>You hereby agree and confirm that Recon infinity shall not be held liable for any
                        unavailability or non-functionality of the Website or Services due to war, industrial action,
                        flood, or act of God. You further agree and confirm that Recon infinity shall not be
                        responsible for any disturbance in the functionality of the Services and/or any unavailability
                        in the Services, arising due to the internet service provider and/or any other third party,
                        and/or any intermediary who may deliver the Services to You and that You shall not hold
                        Recon infinity liable for any of the same.
                    </p>

                    <h4 className='sub_heading'>TERMINATION OF SERVICES</h4>
                    <p className='desc'>Nevertheless any remedies that may be available under any Applicable Law, Recon
                        infinity may temporarily or permanently deny, limit, suspend, or terminate the Services
                        provided to You, if Recon infinity believes that: (a) You have mistreated Your rights to use
                        the Services; or (b) You are in breach of this Terms or Privacy Policy; or, (c) You have
                        performed any act or omission that violates any Applicable Law, rules, or regulations; or,
                        (d) You have performed any act or omission which is harmful or likely to be harmful to
                        Recon infinity, or any other third party, including other users or suppliers of Recon infinity
                        or the service providers; or, (e) You made use of the Services to perform an illegal act, or
                        for the purpose of enabling, facilitating, assisting or inducing the performance of such an
                        act; or, (f) You made any confidential and/or proprietary information pertaining to the
                        Recon infinity and/or the Services public without the prior written consent of Recon infinity.
                        You agree that notwithstanding the closure, Recon infinity will continue to be entitled to
                        use the information supplied by You or collected from You during the use and access of </p>

                    <h4 className='sub_heading'>LIMITATION OF LIABILITY</h4>
                    <p className='desc'>RECON INFINITY, INCLUDING ITS OFFICERS, DIRECTORS, SHAREHOLDERS,
                        EMPLOYEES, SUB-CONTRACTORS, AGENTS, PARENT COMPANIES, SISTER
                        COMPANIES, SUBSIDIARIES AND OTHER AFFILIATES WILL NOT BE LIABLE, TO
                        THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW, FOR ANY DIRECT,
                        INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, OR ANY OTHER DAMAGE
                        AND LOSS (INCLUDING LOSS OF PROFIT, LOSS OF DATA AND WORK STOPPAGE),
                        COSTS, EXPENSES AND PAYMENTS, REGARDLESS OF THE ALLEGED LIABILITY
                        OR FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE,
                        INCLUDING NEGLIGENCE, INTELLECTUAL PROPERTY INFRINGEMENT, PRODUCT
                        LIABILITY AND STRICT LIABILITY, THAT MAY RESULT FROM, OR IN CONNECTION
                        WITH THE USE OR THE INABILITY TO ACCESS AND USE THE WEBSITE OR THE
                        SERVICES, OR FROM ANY FAILURE, ERROR, OR DOWNTIME IN THE FUNCTION
                        OF THE SERVICES, OR FROM ANY FAULT OR ERROR MADE BY RECON INFINITY'S
                        STAFF, OR FROM YOUR RELIANCE ON CONTENT DELIVERED THROUGH THE
                        SERVICES, OR FROM ANY COMMUNICATION WITH RECON INFINITY OR FROM
                        ANY DENIAL OR CANCELLATION OF REQUEST FOR INFORMATION THROUGH THE
                        SERVICES, OR FROM RETENTION, DELETION, DISCLOSURE OR ANY OTHER USE
                        OR LOSS OF CONTENT THROUGH THE SERVICES, REGARDLESS OF WHETHER
                        RECON INFINITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </p>


                    <h4 className='sub_heading'>DISCLAIMER OF WARRANTIES</h4>
                    <p className='desc'>APART FROM AS SPECIFICALLY SET FORTH IN THIS TERMS, RECON INFINITY
                        PARTICULARLY DISCLAIMS ANY OTHER WARRANTY WITH RESPECT TO THE USE
                        OF THE WEBSITE/SERVICES OR ANY CONTENT OR INFORMATION DELIVERED OR
                        SENT THROUGH THE SERVICES TO YOU. THE SERVICES ARE PROVIDED WITHOUT
                        ANY EXPRESS OR IMPLIED GUARANTEE OR ASSURANCE OF QUALITY, RELIABILITY
                        OF THE INFORMATION DELIVERED THROUGH THE SERVICES. RECON INFINITY
                        DISCLAIMS ALL EXPRESS AND IMPLIED WARRANTIES WITH REGARD TO THE
                        SERVICES. RECON INFINITY DOES NOT WARRANT OR GUARANTEE THAT THE USE
                        OF THE SERVICES WILL NOT CAUSE ANY DAMAGES TO YOUR MOBILE PHONE,
                        COMPUTER/LAPTOP AND ANY OTHER COMPATIBLE DEVICE OR TO ANY OTHER
                        SERVICES PROVIDED TO YOUR MOBILE PHONE, COMPUTER/LAPTOP AND ANY
                        OTHER COMPATIBLE DEVICE OR WEBSITE AND CONTENT THAT RESIDE ON YOUR
                        MOBILE PHONE, COMPUTER/LAPTOP AND ANY OTHER COMPATIBLE DEVICE. YOU
                        AGREE AND ACKNOWLEDGE THAT THE USE, AND ACCESS OF THE SERVICES IS
                        ENTIRELY, OR AT THE MAXIMUM PERMITTED BY THE RELEVANT LAW, AT YOUR
                        OWN RISK.</p>
                    <p className='desc'>RECON INFINITY DOES NOT WARRANT OR GUARANTEE THAT THE WEBSITE
                        AND SERVICES WILL OPERATE IN AN ENDLESS, TIMELY, SECURE, OR ERROR-FREE
                        MANNER OR THAT THE SERVICES WILL ALWAYS BE AVAILABLE OR FREE FROM
                        ERRORS OR THAT THE SERVICES WILL BE PROTECTED FROM UNLAWFUL ACCESS.</p>
                    <p className='desc'>RECON INFINITY DOES NOT WARRANT, GUARANTEE OR MAKE ANY
                        REPRESENTATIONS THAT YOU WILL FIND THE SERVICES SUITABLE FOR YOUR
                        NEEDS. RECON INFINITY DOES NOT WARRANT OR GUARANTEE THAT THE
                        INFORMATION YOU RECEIVE TO YOUR MOBILE, COMPUTER/LAPTOP AND ANY
                        OTHER COMPATIBLE DEVICES WILL BE FREE FROM TECHNICAL INACCURACIES.</p>
                    <p className='desc'>4 RECON INFINITY CANNOT AND DOES NOT GUARANTEE THAT THE WEBSITE
                        AND/OR SERVICES WILL BE FREE FROM VIRUSES AND/OR OTHER CODE THAT MAY
                        HAVE CONTAMINATING OR DESTRUCTIVE ELEMENTS. IT IS YOUR RESPONSIBILITY
                        TO IMPLEMENT APPROPRIATE SECURITY SAFEGUARDS (INCLUDING ANTI-VIRUS
                        AND OTHER SECURITY CHECKS) TO SATISY YOUR PARTICULAR REQUIREMENTS
                        AS TO THE SAFETY AND RELIABILITY OF THE SYSTEM.</p>
                    <p className='desc'>RECON INFINITY WILL BE ENTITLED TO USE, SUBJECT TO THE PRIVACY
                        POLICY AS DEFINED ABOVE, ANY INFORMATION SUPPLIED BY YOU DURING THE
                        COURSE OF ACCESSING AND USING THE SERVICES.</p>

                    <h4 className='sub_heading'>INDEMNIFICATION</h4>
                    <p className='desc'>You agree to indemnify and hold Recon infinity, the licensors, business partners of Recon
                        infinity or the service providers and their respective officers, directors, shareholders,
                        employees, sub-contractors, agents, parent companies, sister companies, subsidiaries
                        and other affiliates, indemnified and harmless from any claim or demand, including
                        reasonable attorneys' fees, made by any third party due to or arising out of Your use of
                        the Website or Services, any unauthorised use of the Website/Services by You, Your
                        violation of these Terms, or Your violation of any rights including rights in any form of
                        intellectual property of any third party and/or any violation of applicable law by You.
                    </p>

                    <h4 className='sub_heading'>REVISIONS TO THE TERMS </h4>
                    <p className='desc'>Recon infinity may revise and modify this Terms from time to time, including any and all
                        documents and policies incorporated thereto without any prior notice to You. You agree
                        to be bound by any of the changes made in the Terms, including changes to any and all
                        documents and policies merged thereto. Continuing to access and use the Services will
                        indicate Your acceptance of the revised Terms. If You do not agree with any of the
                        amended Terms, then You must avoid any further use and access of the Services. Recon
                        infinity advises You to periodically read these Terms, as it may change from time to time.</p>

                    <h4 className='sub_heading'>ENTIRE AGREEMENT</h4>
                    <p className='desc'>The "Terms" including the Privacy Policy constitutes the entire agreement between You
                        and Recon infinity and governs Your access and use of the Services, overriding any prior
                        agreements between You and Recon infinity with respect to the Website and Services.</p>

                </div>
            </section>
        </>
    )
}

export default TermsConditions