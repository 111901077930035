import React from 'react'
import Background from "../../Images/dot-bg.png";

const LetsTalk = () => {
    return (

        <>
            <section
                className="letstalk_sec"
                style={{ backgroundImage: `url(${Background})` }}
            >
                <div className="cust_container">
                    <h6 className="sec_name">LET'S TALK</h6>
                    <h4 className="sec_heading">Contact Us</h4>
                    <form id="contact-form" className="contact-form form">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control"
                                        required=""
                                        placeholder="Your Name"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="form-control"
                                        required=""
                                        placeholder="Your Email"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        required=""
                                        className="form-control"
                                        placeholder="Your Phone"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        className="form-control"
                                        required=""
                                        placeholder="Your Subject"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        className="form-control"
                                        id="message"
                                        cols={30}
                                        rows={6}
                                        required=""
                                        placeholder="Your Message"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <button type="submit" className="default-btn submit-btn">
                                    Send Message <span />
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>


    )
}

export default LetsTalk