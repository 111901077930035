import React from 'react'
import "../Home/Home.css";
import HomeBanner from './HomeBanner';
import HomeMarquee from './HomeMarquee';
import HomeAbout from './HomeAbout';
import HomeService from './HomeService';
import HomeDoctor from './HomeDoctor';
import HomeCourse from './HomeCourse';
import HomeTestimonial from './HomeTestimonial';
import HomeTheBridge from './HomeTheBridge';

const Index = () => {
  return (
    <>
        <HomeBanner />
        <HomeMarquee />
        <HomeAbout />
        <HomeService />
        <HomeDoctor />
        <HomeCourse />
        <HomeTheBridge />
        {/* <HomeTestimonial /> */}
    </>
  )
}

export default Index